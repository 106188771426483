var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history-export"},[_c('div',{staticClass:"header"},[_c('v-row',[_c('v-col',{staticClass:"style-title-setting-global"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Gestion des historiques exports excel' : 'Excel export history Management')+" ")]),_c('v-col',[(_vm.getHistoryExportError)?_c('div',{staticClass:"error-msg mt-2"},[(Array.isArray(_vm.getHistoryExportError))?_c('ul',_vm._l((_vm.getHistoryExportError),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.getHistoryExportError))])]):_vm._e()]),_c('v-col',{staticClass:"mt-0"},[_c('v-text-field',{attrs:{"clearable":"","prepend-inner-icon":'mdi-magnify',"label":_vm.$t('search'),"outlined":"","placeholder":_vm.$t('search'),"persistent-placeholder":true,"dense":"","hide-details":"","color":"#5C2DD3"},on:{"input":_vm.filter},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}})],1)],1)],1),(_vm.initLoading)?_c('div',{staticClass:"loader-content"},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"#5C2DD3"}})],1):_c('div',{staticClass:"content-tab-history-export mt-3"},[_c('div',{staticClass:"table-users mt-2"},[_c('v-data-table',{staticClass:"table-users",attrs:{"headers":_vm.$i18n.locale == 'fr' ? _vm.fields : _vm.fieldsEn,"items":_vm.getlistHistoryExportExcel,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"server-items-length":_vm.countPagination,"footer-props":{
          'items-per-page-options': _vm.perPageList
        },"calculate-widths":false,"fixed-header":true,"hide-action":"","no-data-text":_vm.getHistoryExportLoading
            ? _vm.$i18n.locale == 'fr'
              ? 'Chargement... Veuillez patienter'
              : 'Loading... Please wait'
            : _vm.$i18n.locale == 'fr'
            ? `Il n'y a aucune donnée à afficher dans ce tableau.`
            : 'There is no data to display in this table.'},on:{"update:page":[function($event){return _vm.$set(_vm.pagination, "page", $event)},_vm.handlePaginationChange],"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:`item.nom`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.user.full_name)+" ")])]}},{key:`item.filter`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[(item && item.filter)?_c('div',_vm._l((item.filter),function(filter,index){return _c('div',{key:index,staticClass:"nom_user_filtre"},[_vm._v(" "+_vm._s(filter)+" ")])}),0):_c('div',{staticClass:"color-crm"},[_vm._v("Aucun filtre")])])]}},{key:`item.type_projet`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.dtype.name)+" ")])]}},{key:`item.time_export`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell"},[_vm._v(" "+_vm._s(item.timestamp)+" ")])]}},{key:`item.lien`,fn:function({ item }){return [_c('td',{staticClass:"custom-cell text-center"},[_c('a',{staticClass:"nom_user_lien color-crm",attrs:{"href":item.lien,"title":_vm.$i18n.locale == 'fr'
                  ? 'Télécharger un fichier Excel'
                  : 'Download Excel file'}},[_c('font-awesome-icon',{staticClass:"img-h-19",attrs:{"icon":"download"}})],1)])]}}],null,true),model:{value:(_vm.selectedTable),callback:function ($$v) {_vm.selectedTable=$$v},expression:"selectedTable"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }