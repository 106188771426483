<template>
  <div class="history-export">
    <div class="header">
      <v-row>
        <v-col class="style-title-setting-global">
          {{
            $i18n.locale === 'fr'
              ? 'Gestion des historiques exports excel'
              : 'Excel export history Management'
          }}
        </v-col>
        <v-col>
          <div v-if="getHistoryExportError" class="error-msg mt-2">
            <ul v-if="Array.isArray(getHistoryExportError)">
              <li v-for="(e, index) in getHistoryExportError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getHistoryExportError }}</span>
          </div>
        </v-col>
        <v-col class="mt-0">
          <v-text-field
            v-model="full_name"
            @input="filter"
            clearable
            :prepend-inner-icon="'mdi-magnify'"
            :label="$t('search')"
            outlined
            :placeholder="$t('search')"
            :persistent-placeholder="true"
            dense
            hide-details
            color="#5C2DD3"
          ></v-text-field
        ></v-col>
      </v-row>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else class="content-tab-history-export mt-3">
      <div class="table-users mt-2">
        <v-data-table
          v-model="selectedTable"
          :headers="$i18n.locale == 'fr' ? fields : fieldsEn"
          :items="getlistHistoryExportExcel"
          :page.sync="pagination.page"
          :items-per-page="pagination.itemsPerPage"
          :server-items-length="countPagination"
          @update:page="handlePaginationChange"
          @update:items-per-page="changePerPage"
          :footer-props="{
            'items-per-page-options': perPageList
          }"
          class="table-users"
          :calculate-widths="false"
          :fixed-header="true"
          hide-action
          :no-data-text="
            getHistoryExportLoading
              ? $i18n.locale == 'fr'
                ? 'Chargement... Veuillez patienter'
                : 'Loading... Please wait'
              : $i18n.locale == 'fr'
              ? `Il n'y a aucune donnée à afficher dans ce tableau.`
              : 'There is no data to display in this table.'
          "
        >
          <!-- BODY TABLE -->

          <template v-slot:[`item.nom`]="{ item }">
            <td class="custom-cell">
              {{ item.user.full_name }}
            </td>
          </template>
          <template v-slot:[`item.filter`]="{ item }">
            <td class="custom-cell">
              <div v-if="item && item.filter">
                <div
                  class="nom_user_filtre"
                  v-for="(filter, index) in item.filter"
                  :key="index"
                >
                  {{ filter }}
                </div>
              </div>
              <div v-else class="color-crm">Aucun filtre</div>
            </td>
          </template>

          <template v-slot:[`item.type_projet`]="{ item }">
            <td class="custom-cell">
              {{ item.dtype.name }}
            </td>
          </template>
          <template v-slot:[`item.time_export`]="{ item }">
            <td class="custom-cell">
              {{ item.timestamp }}
            </td>
          </template>
          <template v-slot:[`item.lien`]="{ item }">
            <td class="custom-cell text-center">
              <a
                :href="item.lien"
                class="nom_user_lien color-crm "
                :title="
                  $i18n.locale == 'fr'
                    ? 'Télécharger un fichier Excel'
                    : 'Download Excel file'
                "
              >
                <font-awesome-icon class="img-h-19" icon="download" />
              </a>
            </td>
          </template>
        </v-data-table>
      </div>
      <!-- <div class="footer-style-table mt-2">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="handlePaginationChange"
            :total-visible="7"
            color="#5C2DD3"
            item-color="#5C2DD3"
            circle
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            label="Eléments par page"
            outlined
            dense
            hide-details
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      fields: [
        {
          value: 'nom',
          text: 'Utilisateur',
          thClass: 'width-th-nom',
          tdClass: 'width-th-nom',
          sortable: false
        },
        {
          value: 'filter',
          text: 'Filtre',
          thClass: 'width-th-filtre',
          tdClass: 'width-th-filtre',
          sortable: false
        },
        {
          value: 'type_projet',
          text: 'Type de projet',
          thClass: 'width-th-type',
          tdClass: 'width-th-type',
          sortable: false
        },
        {
          value: 'time_export',
          text: 'Date export Excel',
          thClass: 'width-th-date',
          tdClass: 'width-th-date',
          sortable: false
        },

        // Lien export Excel
        {
          value: 'lien',
          text: 'Actions',
          thClass: 'width-th-lien',
          tdClass: 'width-th-lien',
          sortable: false
        }
      ],
      fieldsEn: [
        {
          value: 'nom',
          text: 'Users',
          thClass: 'width-th-nom',
          tdClass: 'width-th-nom',
          sortable: false
        },
        {
          value: 'filter',
          text: 'Filter',
          thClass: 'width-th-filtre',
          tdClass: 'width-th-filtre',
          sortable: false
        },
        {
          value: 'type_projet',
          text: 'Project type',
          thClass: 'width-th-type',
          tdClass: 'width-th-type',
          sortable: false
        },
        {
          value: 'time_export',
          text: 'Date export Excel',
          thClass: 'width-th-date',
          tdClass: 'width-th-date',
          sortable: false
        },

        // Lien export Excel
        {
          value: 'lien',
          text: 'Actions',
          thClass: 'width-th-lien',
          tdClass: 'width-th-lien',
          sortable: false
        }
      ],
      page: 1,
      per_page: 10,
      full_name: null,
      initLoading: true,
      selectedTable: [],
      perPageList: [10, 20, 30, 50],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItems: this.countPagination
      }
    }
  },
  methods: {
    ...mapActions(['fetchListHistoryExportExcel']),
    changePerPage(newPerPage) {
      this.pagination.itemsPerPage = newPerPage
      this.pagination.page = 1
      this.per_page = newPerPage
      this.filter()
    },
    handlePaginationChange(pagination) {
      this.page = pagination
      this.pagination.page = pagination
      this.fetchListHistoryExportExcel({
        page: this.page,
        per_page: this.per_page,
        full_name: this.full_name
      })
    },
    filter() {
      this.page = 1
      this.fetchListHistoryExportExcel({
        page: this.page,
        per_page: this.per_page,
        full_name: this.full_name
      })
    }
  },
  computed: {
    ...mapGetters([
      'getlistHistoryExportExcel',
      'getHistoryExportError',
      'getHistoryExportLoading',
      'countPagination'
    ]),
    totalPages() {
      if (this.countPagination) {
        return Math.ceil(this.countPagination / this.per_page)
      }
      return this.countPagination
    }
  },
  watch: {
    full_name(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.autofocus) {
            this.$refs.autofocus.focus()
          }
        })
      }
    }
  },
  async mounted() {
    await this.fetchListHistoryExportExcel({
      page: this.page,
      per_page: this.per_page
    })
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.history-export {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
  }
}
</style>
<style lang="scss">
.content-tab-history-export {
  .width-th-nom {
    width: 12%;
  }
  .width-th-filtre {
    width: 20%;
    text-align: initial;
    text-transform: capitalize;
  }
  .width-th-type {
    width: 6%;
  }
  .width-th-lien {
    width: 4%;
  }
  .width-th-date {
    width: 10%;
  }
  .table-users {
    table {
      border-collapse: collapse;
    }

    height: 100% !important;
    max-height: 100% !important;
    .header-users {
      th {
        text-align: left;
        padding: 0px 14px;
        height: 40px;
        font-size: 14px;
        white-space: nowrap;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    thead {
      tr {
        th {
          white-space: nowrap;
          color: rgb(0 0 0) !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px !important;
          letter-spacing: 0.17px;
          line-height: 17.16px;
          font-family: 'Montserrat', sans-serif;
        }
      }
    }
    .v-data-table__wrapper::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .v-data-table__wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .v-data-table__wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
    .v-data-table__wrapper {
      cursor: pointer;
      max-height: calc(100vh - 264px) !important;
      height: calc(100vh - 264px) !important;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: auto;
    }
  }
}
</style>
